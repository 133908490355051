<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="인허가 검토정보">
        <template slot="card-button">
          <q-btn-group outline>
            <!-- <c-btn
              v-show="editable && !disabled && receiptEditable"
              :url="receiptUrl"
              :isSubmit="isReceipt"
              :param="licenseRequest"
              mappingType="PUT"
              label="요청접수"
              icon="save"
              @beforeAction="receiptData"
              @btnCallback="receiptCallback" /> -->
            <c-btn
              v-show="editable && !disabled && completeEditable"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="licenseRequest"
              mappingType="PUT"
              label="검토완료"
              icon="check"
              @beforeAction="completeData"
              @btnCallback="completeCallback" />
            <c-btn
              v-show="editable && !disabled && completeEditable"
              :url="rejectUrl"
              :isSubmit="isReject"
              :param="licenseRequest"
              mappingType="PUT"
              label="기각"
              icon="delete"
              @beforeAction="rejectData"
              @btnCallback="rejectCallback" />
            <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="licenseRequest"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              :disabled="true"
              :editable="editable"
              codeGroupCd="LICENSE_REQUEST_STEP_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="requestStepCd"
              label="진행단계"
              v-model="licenseRequest.requestStepCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
            <c-text
              :disabled="disabled"
              :editable="editable"
              label="기각사유"
              name="dismissReason"
              v-model="licenseRequest.dismissReason">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="인허가 검토 목록"
      :columns="grid.columns"
      :data="licenseRequest.reviews"
      :gridHeight="grid.height"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :editable="editable&&!disabled"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "license-request-status",
  props: {
    param: {
      type: Object,
      default: () => ({
        limLicenseRequestId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      licenseRequest: {
        limLicenseRequestId: '',  // 인허가 요청 일련번호
        plantCd: '',  // 사업장 코드
        licenseRequestName: '',  // 인허가 요청명
        requestDeptCd: '',  // 요청부서 코드
        requestUserId: '',  // 요청자
        completeRequestDate: '',  // 인허가 완료 요청일
        requestContent: '',  // 요청 내용
        sopMocId: '',  // MOC 일련번호
        requestStepCd: 'RSC0000001',  // 요청및검토 진행 단계 : RSC0000002(요청),RSC0000003(검토 및 진행중),RSC0999999(기각),RSC1000000(완료)
        dismissReason: '',  // 기각사유
        reviewOpinion: '',  // 검토 종합 의견
        reviewUserId: '',  // 종합 검토자
        reviewDeptCd: '',  // 검토 부서
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        reviews: [],
        deleteReviews: [],
      },
      grid: {
        columns: [
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'center',
            style: 'widht: 150px',
            sortable: false,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            label: '인허가종류',
            align: 'center',
            style: 'widht: 150px',
            sortable: false,
          },
          {
            name: 'review',
            field: 'review',
            label: '검토',
            sortable: false,
            child: [
              {
                name: 'reviewDeptName',
                field: 'reviewDeptName',
                label: '부서',
                align: 'center',
                style: 'widht: 120px',
                sortable: false,
              },
              {
                name: 'reviewUserName',
                field: 'reviewUserName',
                label: '검토자',
                align: 'center',
                style: 'widht: 100px',
                sortable: false,
              },
            ]
          },
          {
            name: 'limLicenseKindStatusCd',
            field: 'limLicenseKindStatusCd',
            label: '검토상태',
            align: 'center',
            style: 'widht: 120px',
            type: 'select',
            codeGroupCd: 'LIM_LICENSE_KIND_STAUS_CD',
            sortable: false,
          },
          {
            name: 'renewalFlag',
            field: 'renewalFlag',
            label: '갱신여부',
            align: 'center',
            style: 'widht: 80px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
            disableTarget: 'dis',
            disableCon: 'X'
          },
          {
            name: 'asLicenseSeqName',
            field: 'asLicenseSeqName',
            label: '갱신대상 인허가',
            align: 'center',
            style: 'widht: 180px',
            sortable: false,
          },
          {
            name: 'reviewOpinion',
            field: 'reviewOpinion',
            label: '검토 의견',
            align: 'left',
            style: 'widht: 250px',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      isSave: false,
      // isReceipt: false,
      isComplete: false,
      isReject: false,
      saveUrl: '',
      // receiptUrl: '',
      completeUrl: '',
      rejectUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isReviewUser() {
      return this.licenseRequest.reviewUserId === this.$store.getters.user.userId 
        || this.licenseRequest.reviewDeptCd === this.$store.getters.user.deptCd
    },
    // receiptEditable() {
    //   return this.licenseRequest.requestStepCd === 'RSC0000002' 
    //     && this.isReviewUser
    // },
    completeEditable() {
      return this.licenseRequest.requestStepCd === 'RSC0000003' 
        && this.isReviewUser
    },
    disabled() {
      return this.licenseRequest.requestStepCd === 'RSC0999999'
        || this.licenseRequest.requestStepCd === 'RSC1000000'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  deactivated() {
    window.removeEventListener('resize', this.setSize);
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.lim.lincense.request.get.url;
      this.saveUrl = transactionConfig.sop.lim.lincense.request.update.url;
      // this.receiptUrl = transactionConfig.sop.lim.lincense.request.reviewRecepit.url;
      this.completeUrl = transactionConfig.sop.lim.lincense.request.reviewComplete.url;
      this.rejectUrl = transactionConfig.sop.lim.lincense.request.reviewReject.url;
      // code setting
      this.setSize();
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.limLicenseRequestId) {
        this.$http.url = this.$format(this.detailUrl, this.param.limLicenseRequestId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.licenseRequest, _result.data);
        },);
      }
    },
    setSize() {
      let height = window.innerHeight - 300;
      if (height < 450) {
        height = 450;
      }
      this.grid.height = String(height) + 'px'
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.licenseRequest.reviews, this.$language('인허가 검토'))) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.licenseRequest.chgUserId = this.$store.getters.user.userId;

            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    // receiptData() {
    //   if (this.$comm.validTable(this.grid.columns, this.licenseRequest.reviews, '인허가 검토')) {
    //     window.getApp.$emit('CONFIRM', {
    //       title: 'LBLCONFIRM',  // 확인
    //       message: '요청에 대하여 [접수처리] 하시겠습니까?',
    //       
    //       type: 'warning', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.licenseRequest.chgUserId = this.$store.getters.user.userId;

    //         this.isReceipt = !this.isReceipt
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
    // receiptCallback(result) {
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.getDetail();
    //   this.$emit('emitStep', {
    //     name: 'currentStep',
    //     param: result.data.requestStepCd
    //   })
    // },
    completeData() {
      if (this.$comm.validTable(this.grid.columns, this.licenseRequest.reviews, this.$language('인허가 검토'))) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '인허가종류별 검토가 모두 완료처리됩니다. 검토완료 하시겠습니까?',
          
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.licenseRequest.chgUserId = this.$store.getters.user.userId;

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
      this.$emit('emitStep', {
        name: 'currentStep',
        param: result.data.requestStepCd
      })
    },
    rejectData() {
      if (!this.licenseRequest.dismissReason) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '기각사유를 입력하십시요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      if (this.$comm.validTable(this.grid.columns, this.licenseRequest.reviews, this.$language('인허가 검토'))) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '요청에 대하여 [기각처리] 하시겠습니까?',
          
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.licenseRequest.chgUserId = this.$store.getters.user.userId;

            this.isReject = !this.isReject
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    rejectCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
      this.$emit('emitStep', {
        name: 'currentStep',
        param: result.data.requestStepCd
      })
    },
  }
};
</script>
