var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "인허가 검토정보" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.completeEditable,
                            expression:
                              "editable && !disabled && completeEditable",
                          },
                        ],
                        attrs: {
                          url: _vm.completeUrl,
                          isSubmit: _vm.isComplete,
                          param: _vm.licenseRequest,
                          mappingType: "PUT",
                          label: "검토완료",
                          icon: "check",
                        },
                        on: {
                          beforeAction: _vm.completeData,
                          btnCallback: _vm.completeCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.completeEditable,
                            expression:
                              "editable && !disabled && completeEditable",
                          },
                        ],
                        attrs: {
                          url: _vm.rejectUrl,
                          isSubmit: _vm.isReject,
                          param: _vm.licenseRequest,
                          mappingType: "PUT",
                          label: "기각",
                          icon: "delete",
                        },
                        on: {
                          beforeAction: _vm.rejectData,
                          btnCallback: _vm.rejectCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable && !disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.licenseRequest,
                          mappingType: "PUT",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        codeGroupCd: "LICENSE_REQUEST_STEP_CD",
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "requestStepCd",
                        label: "진행단계",
                      },
                      model: {
                        value: _vm.licenseRequest.requestStepCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseRequest, "requestStepCd", $$v)
                        },
                        expression: "licenseRequest.requestStepCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "기각사유",
                        name: "dismissReason",
                      },
                      model: {
                        value: _vm.licenseRequest.dismissReason,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseRequest, "dismissReason", $$v)
                        },
                        expression: "licenseRequest.dismissReason",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-table", {
        ref: "table",
        staticClass: "q-mt-sm",
        attrs: {
          title: "인허가 검토 목록",
          columns: _vm.grid.columns,
          data: _vm.licenseRequest.reviews,
          gridHeight: _vm.grid.height,
          columnSetting: false,
          isFullScreen: false,
          usePaging: false,
          editable: _vm.editable && !_vm.disabled,
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }